@import "../../styles/base.scss";

.BeforeYouProceed {
  max-width: $desktop-max-width;
  padding: 1.2rem $desktop-margin;
  font-family: BaskervilleMTStd,serif;
  font-size: 1.375rem;
  line-height: 1.45455;
  h2 {
    font-size: 3rem;
    font-weight: 400;
    line-height: 1.16667;
    margin-top: 0;
    font-family: "BaskervilleMTStd","Times New Roman";
  }
  a {
    color: $black;
    text-decoration: none;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    transition: border-color .2s ease,background-color .2s ease,color .2s ease;
    &:hover {
      border-color: transparent;
    }
  }

  @media screen and (max-width: $mobile) {
    .rich-text {
      > div {
        display: flex;
        flex-direction: column;
        a {
          margin-bottom: 20px;
        }
        button {
          margin-left: 0 !important;
        }
      }
    }
  }
}
