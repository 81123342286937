@import "../../styles/base.scss";

.CategorizedSearch {
  @include flexBox($flexDirection: column);
  width: calc(33.33% - .83333rem);
  .header {
    @include font($fontFamily: $font-lato, $size: 1rem, $weight: 400, $lh: 1.5, $ls: .03rem);
    margin: 0;
  }
  .search-results {
    li {
      padding: .1875rem 0;
      a {
        @include flexBox();
        @include font($fontFamily: $font-lato, $size: .75rem, $weight: 700, $lh: 1.66667, $color: $mine-shaft);
        text-decoration: none;
        &:hover {
          color: $black;
        }
      }
      .search-result-type {
        @include font($weight: 400, $style: italic, $ls: .02rem, $color: $mine-shaft);
        display: inline-block;
        margin-right: 3rem;
        text-transform: capitalize;
      }
      &.no-results {
        @include font($fontFamily: $font-baskerville, $size: 1rem, $weight: 400, $lh: 1rem, $color: $black);
      }
    }
  }
  .content-advisory {
    @include font($fontFamily: $font-lato, $size: .875rem, $weight: 400, $lh: 1.5);
    margin-top: .9375rem;
    h5 {
      @include font($size: .875rem, $weight: 700, $lh: 1.5);
      margin: 0;
    }
    p {
      margin: .875rem 0 0;
    }
  }
}

@media screen and (max-width: $tablet-potrait) {
  .CategorizedSearch {
    width: 100%;
  }
}

@media screen and (max-width: $mobile) {
  .CategorizedSearch {
    margin-bottom: 1.875rem;
  }
}
