@import "../../styles/base.scss";

.AppDownloads {
  font-family: $font-baskerville;
  position: relative;
  max-width: 60.12987%;
  width: 100%;
  &-intro {
    h2 {
      @include font($fontFamily: $font-baskerville, $size: 3rem, $weight: 400, $lh: 1.16667);
      margin-top: 0;
    }
    p {
      @include font($size: 1.75rem, $weight: 400, $lh: 1.28571);
      margin: 1.875rem 0 0;
      img {
        object-fit: contain;
      }
    }
  }
  &-apps-list {
    p {
      @include font($size: 1.375rem, $weight: 400, $lh: 1.45455);
      margin: 1.875rem 0 0;
    }
    ul {
      @include flexBox($alignItems: flex-start, $justifyContent: space-between, $flexWrap: wrap);
      margin-top: .9375rem;
      li {
        margin-bottom: .9375rem;
        margin-right: .9375rem;
      }
    }
  }
  &-intro-ctas {
    margin-top: 1.875rem;
    a:last-child {
      margin-left: 0.625rem !important;
    }
  }
}

@media screen and (max-width: $tablet-potrait) {
  .AppDownloads {
    max-width: 100%;
    margin-bottom: 1.875rem;
    &-intro {
      h2 {
        @include font($size: 2rem, $lh: 1.25);
      }
      p {
        @include font($size: 1.25rem, $lh: 1.4);
      }
    }
    &-apps-list {
      ul {
        justify-content: flex-start;
      }
      p {
        @include font($size: 1.125rem, $lh: 1.55556);
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .AppDownloads {
    max-width: 100%;
    &-apps-list ul {
      display: block;
      li {
        display: inline-block;
      }
    }
  }
}
.student-tools {
  margin-bottom: 1rem;
  li {
    margin-top: 0.625rem;
  }
}
.student-link {
  position: relative;
  text-decoration: none;
  color: $black;

  font-family: 'Lato';
  font-weight: bold;
  font-size: 0.75rem;
  &:hover {
    text-decoration: underline;
  }
}
.student-link::after {
  content: "";
    background-image: url(../../assets/images/icons/right-arrow-black.svg);
    width: 0.625rem;
    height: 0.625rem;
    background-size: contain;
    display: inline-block;
    background-repeat: no-repeat;
    position: absolute;
    right: -0.625rem;
    bottom: 0;
    top: 50%;
    -webkit-transform: translateY(-50%) translateX(50%);
    transform: translateY(-50%) translateX(50%);
}

.link-to-catalog {
  padding: 1rem 0;
  a {
    @include font($fontFamily: $font-lato, $size: .75rem, $weight: 700, $lh: 1.66667, $color: $mine-shaft);
    text-decoration: none;
    &:hover {
      cursor:pointer;
      color: $black;
    }
  }
}
