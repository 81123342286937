@import "../../styles/base.scss";

.performance {
  &-actions {
    @include flexBox($justifyContent: flex-start);
    margin-bottom: 1.5rem;
  }
  &-favorite {
    @include flexBox();
    cursor: pointer;
    margin-right: 3.5rem;
    span {
      @include font($fontFamily: $font-lato, $size: 1rem, $weight: 400, $lh: 1.5, $ls: .03rem);
      text-transform: uppercase;
      &:first-child {
        @include flexBox($justifyContent: flex-start);
        width: 1.0625rem;
        height: 1.0625rem;
        margin: 0.2rem 0.625rem 0 0;
        svg {
          width: 1.0625rem;
          height: 1.0625rem;
          stroke-width: 0.125rem;
          stroke: $white;
          fill: transparent;
        }
      }
    }
    &.in-favorites {
      span:first-child svg {
        stroke: $pink;
        fill: $pink;
      }
    }
  }
  &-share {
    &-trigger {
      p {
        @include font($fontFamily: $font-lato, $size: 1rem, $weight: 400, $lh: 1.5, $ls: .03rem);
        text-transform: uppercase;
        margin: 0;
        cursor: pointer;
        img {
          display: inline-block;
          width: .875rem;
          height: .875rem;
          margin-right: .6rem;
        }
      }
    }
  }
  &-social-share {
    position: relative;
    cursor: pointer;
    &-buttons{
      @include flexBox();
      position: absolute;
      left: 0;
      top: -2.5rem;
      height: 2.5rem;
      a {
        display: none;
        position: relative;
        border: none;
        border-radius: 100%;
        width: 1.4375rem;
        height: 1.4375rem;
        margin-right: .3125rem;
        overflow: hidden;
        svg {
          height: .875rem;
          width: .875rem;
          border: none;
          fill: $white;
        }
        &.fb {
          background-color: $facebook-blue;
        }
        &.twitter {
          background-color: $twitter-blue;
        }
      }
    }
    &:hover {
      .performance-social-share-buttons a {
        @include flexBox($alignItems: center, $justifyContent: center);
      }
    }
  }
}

@media screen and (max-width: $tablet) {
  .performance {
    &-favorite {
      margin-right: 1.2rem;
    }
  }
}

@media screen and (max-width: $mobile) {
  .performance {
    &-favorite {
      margin-right: 1.5rem;
    }
  }
}
