@import "../../styles/base.scss";

.Login {
  position: relative;
  max-width: $desktop-max-width;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: $desktop-margin;
  overflow: hidden;
  &-close {
    @include flexBox($justifyContent: flex-end);
    cursor: pointer;
    width: 100%;
    height: 25px;
    img {
      width: 25px;
      height: 100%;
      object-fit: contain;
    }
  }
  &-form {
    max-width: $tablet-potrait;
    width: 100%;
    h1 {
      @include font($fontFamily: $font-baskerville, $size: 56px, $weight: 400, $lh: 64px);
      margin: 0 0 $desktop-margin;
    }
    h3 {
      @include font($fontFamily: $font-baskerville, $size: 40px, $weight: 400, $lh: 48px);
      margin: 0 0 $desktop-margin;
    }
    label {
      padding-bottom: 0 !important;
      cursor: default !important;
      &:hover {
        cursor: default !important;
      }
      div {
        @include font($fontFamily: $font-lato, $size: 14px, $weight: 700, $lh: 21px);
        text-transform: uppercase;
        span {
          font-style: italic;
          font-weight: 400;
          text-transform: none;
        }
      }
    }
    input[name="username"], input[name="password"]{
      @include font($fontFamily: $font-baskerville, $size: 28px, $weight: 400, $lh: 36px);
      border: 1px solid $silver !important;
      border-radius: 0;
      padding: 15px 12px !important;
      margin: 0;
      color: $black;
      &:focus, &:hover {
        outline-style: none !important;
        box-shadow: none !important;
        border: 1px solid $silver !important;
      }
    }
    .error-message {
      @include font($fontFamily: $font-baskerville, $size: 22px, $weight: 400, $color: $thunderbird, $lh: 1.5, $style: italic);
      &-container {
        margin: 1.875rem 0;
        ol {
          padding-left: 1.5rem;
          li {
            @include font($fontFamily: $font-baskerville, $size: 22px, $weight: 400, $lh: 32px);
            list-style: revert;
            span {
              border-bottom: .0625rem solid $black;
              cursor: pointer;
              &:hover {
                border-color: transparent;
                -webkit-transition: border-color .2s ease;
                transition: border-color .2s ease;
              }
            }
            &:not(:first-child) {
              margin-top: 1.5rem;
            }
          }
        }
      }
    }
    .form-field {
      margin-bottom: $desktop-margin;
      &.forgot-password {
        a {
          @include font($fontFamily: $font-lato, $size: 14px, $weight: 700, $color: $black, $lh: normal);
          text-transform: uppercase;
          text-decoration: none;
          border-bottom-width: 1px;
          border-bottom-style: solid;
          transition: border-color .2s ease,background-color .2s ease,color .2s ease;
          &:hover {
            color: $thunderbird;
            border-color: transparent;
          }
        }
      }
      &-error {
        label, div {
          color: $thunderbird;
        }
        input, input:focus, input:hover {
          border: 1px solid $thunderbird !important;
        }
        &-message {
          display: flex;
          font-size: .875rem;
          font-style: normal;
          font-weight: 400;
        }
        &-alert {
          fill: $thunderbird;
          width: 1.25rem;
          height: 1.25rem;
          margin-right: 0.625rem;
        }
        svg:not(.form-field-error-alert) {
          display: none !important;
        }
        label > div:last-child {
          padding: .3125rem;
        }
      }
    }
  }
  &-body {
    @include flexBox($alignItems: flex-start, $justifyContent: center);
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 130px 0 0;
    > div {
      flex: 1;
    }
  }
  &-register {
    font-family: $font-baskerville;
    margin: 94px 0 0 40px;
    h3 {
      @include font($fontFamily: $font-baskerville, $size: 40px, $weight: 400, $lh: 48px);
      margin: 0;
    }
    p {
      @include font($size: 22px, $weight: 400, $lh: 32px);
      margin: $desktop-margin 0
    }
  }
  &-disclaimer {
    p {
      @include font($fontFamily: $font-lato, $size: 14px, $weight: 400, $color: $black, $lh: 21px);
      a {
        color: $black;
        text-decoration: none;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        transition: border-color .2s ease,background-color .2s ease,color .2s ease;
        &:hover {
          border-color: transparent;
        }
      }
    }
  }
}

@media screen and (max-width: $tablet) {
  .Login {
    padding: $desktop-margin calc(#{$desktop-margin} / 2);
    &-form {
      h1 {
        @include font($size: 40px, $lh: 48px);
      }
      h3 {
        @include font($size: 28px, $lh: 36px);
      }
      input[name="username"], input[name="password"]{
        @include font($size: 20px, $lh: 28px);
      }
      .error-message {
        @include font($size: 18px, $lh: 28px);
        &-container {
          ol {
            padding-left: 18px;
            li {
              @include font($size: 18px, $lh: 28px);
            }
          }
        }
      }
    }
    &-body {
      padding: 100px 0 0;
    }
    &-register {
      h3 {
        @include font($size: 28px, $lh: 36px);
      }
      p {
        @include font($size: 18px, $lh: 28px);
      }
    }
  }
}

@media screen and (max-width: $tablet-potrait) {
  .Login {
    margin-bottom: 30px;
    &-body {
      flex-direction: column;
    }
    &-register {
      margin: 80px 0 0;
    }
  }
}
