@import "../../styles/base.scss";

.Slide {
  @include flexBox($display: flex, $flexDirection: column, $alignItems: center, $justifyContent: center);
  position: absolute;
  width: 100%;
  height: calc(95vh - #{$desktop-margin} * 2);
  display: block;
  &-overlay-image {
    width: 100%;
    height: 100%;
    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center top;
    }
  }
  &.info-active {
    .slide-overlay-info-wrapper {
      opacity: 1;
      pointer-events: all;
    }
  }
  &.active {
    position: absolute;
  }
  &.inactive {
    position: fixed;
  }
}

@media screen and (max-width: $tablet) {
  .Slide {
    position: relative;
    height: 100%;
    &-overlay-image {
      height: 0;
      padding-bottom: 38%;
      overflow: hidden;
      background: none !important;
      img {
        display: block;
      }
    }
    &.active {
      position: relative;
    }
  }
}

@media screen and (max-width: $tablet-potrait) {
  .Slide {
    &-overlay-image {
      height: 100%;
      padding-bottom: 0;
      img {
        position: relative;
      }
    }
  }
}
