@import "../../styles/base.scss";

.AudioBroadcastPage {
  margin: 0 auto;
  background-color: $black;
  color: $white;
  min-height: 100vh;
  .Broadcast-hero {
    @include flexBox($alignItems: center, $justifyContent: center, $flexDirection: column);
    position: relative;
    &-banner {
      width: 100%;
      height: 30rem;
      img {
        @include image(100%, 100%, cover);
        margin: 0;
      }
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(to bottom, transparent 0%, black 100%);
      }
    }
    &-title {
      @include flexBox($justifyContent: center, $flexDirection: column);
      position: absolute;
      margin: 0 auto;
      text-align: center;
      h3 {
        @include font($fontFamily: $font-lato, $size: 3.43rem, $weight: 700, $ls: .086rem);
        padding: 1.25rem;
        margin: 0 auto;
      }
    }
  }
  .Grid {
    max-width: $desktop-max-width;
    width: 100%;
    margin: 0 auto;
    padding: 1.875rem;
    &-contents > a {
      background-color: $white;
      border: 0;
    }
  }
  .performance-title {
    font-size: 3.125rem;
    letter-spacing: .07rem;
  }
}

@media screen and (max-width: $tablet) {
  .AudioBroadcastPage {
    .Broadcast-hero {
      &-banner {
        height: 22rem;
      }
      h3 {
        font-size: 2.5rem;
      }
    }
    .performance-title {
      font-size: 2.4rem;
    }
  }
}

@media screen and (max-width: $tablet-potrait) {
  .AudioBroadcastPage {
    .Broadcast-hero {
      h3 {
        font-size: 2.2rem;
      }
    }
    .performance-title {
      font-size: 2rem;
    }
    .Carousel, .Marketing, .Grid {
      padding: 0 0.9375rem;
    }
  }
}
