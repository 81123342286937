@import "../../styles/base.scss";

.HomePage {
  .content {
    max-width: $desktop-max-width;
    margin: $desktop-margin*2 auto $desktop-margin;
    padding: $desktop-margin;
  }
  .Intro-and-search {
    @include flexBox($justifyContent: space-between, $flexWrap: wrap);
  }
}

@media screen and (max-width: $tablet) {
  .HomePage {
    .content {
      margin: 0;
      padding: 1.875rem .9375rem;
    }
  }
}
