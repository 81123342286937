@import "../../styles/base.scss";

.SearchPage {
  max-width: $desktop-max-width;
  margin: 0 auto;
  padding: 1.2rem $desktop-margin;
  &-header {
    @include flexBox($alignItems: center, $justifyContent: flex-end);
    .search-results-info {
      display: inline-block;
      width: 66%;
      p {
        @include font($fontFamily: $font-baskerville, $size: 2.5rem, $color: $scorpion, $weight: 400, $ls: .06rem);
        margin: 0;
        span {
          font-weight: 700;
          color: $black;
          white-space: nowrap;
          display:block;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &-media-type-counts span {
        @include font($fontFamily: $font-lato, $size: 1rem, $weight: 400, $lh: 1.5, $ls: .03rem);
        text-transform: uppercase;
        &:after {
          content: ",";
          display: inline-block;
          margin-right: 0.25rem;
        }
        &:last-child:after {
          content: "";
        }
      }
    }
    .search-input {
      display: inline-block;
      width: 34%;
      float: right;
    }
  }
  .Grid {
    &-header h2 {
      @include font($fontFamily: $font-lato, $size: 1.37rem, $weight: 700, $ls: .05rem);
      text-transform: uppercase;
      padding-bottom: .37rem;
      border-bottom: .125rem solid $black;
    }
  }
}

@media screen and (max-width: $tablet-potrait) {
  .SearchPage {
    &-header {
      flex-direction: column;
      margin-bottom: 1.875rem;
      .search-results-info, .search-input {
        width: 100%;
        float: none;
        margin-bottom: 1.875rem;
      }
    }
  }
}
