@import "../../styles/base.scss";

.SearchFilters {
  .filters-mobile-cta {
    display: none !important;
  }
  .filter-categories {
    @include flexBox($alignItems: flex-start);
    li {
      border-left: .0625rem solid $boulder;
      margin-left: 1.875rem;
      padding-left: 1.875rem;
      cursor: pointer;
      &:first-child {
        border-left: 0;
        margin-left: 0;
        padding-left: 0;
      }
      > span {
        @include font($fontFamily: $font-lato, $size: .75rem, $weight: 700, $lh: 1.66667);
        display: block;
        text-transform: uppercase;
        &:last-child {
          @include font($fontFamily: $font-baskerville, $size: 1.75rem, $weight: 400, $lh: 1.28571);
          position: relative;
          text-transform: capitalize;
          text-align: center;
          &:after {
            position: relative;
            display: inline-block;
            content: '';
            background-image: url("../../assets/images/icons/icon-arrow-down.svg");
            width: 1rem;
            height: 1rem;
            margin-top: .1875rem;
            margin-left: 1.2rem;
          }
        }
      }
      &.inactive {
        > span {
          color: $boulder;
        }
      }
      &.active {
        > span.has-selected-filter {
          color: $pink;
        }
        &.expanded {
          > span:last-child:after {
            transform: rotate(180deg);
          }
        }
      }
      &.clear-filters {
        @include font($fontFamily: $font-lato, $size: .875rem, $weight: 700, $lh: normal);
        border: .0625rem solid transparent;
        border-bottom-color: $black;
        padding: 0;
        margin-left: 3.75rem;
        text-transform: uppercase;
        transition: border-color .2s ease,background-color .2s ease,color .2s ease;
        text-align: center;
        &:hover {
          color: $thunderbird;
          border-bottom-color: transparent;
        }
      }
    }
  }
  .filter-results {
    margin-top: 1rem;
    & > div {
      @include flexBox($alignItems: center, $justifyContent: space-between, $flexWrap: wrap);
      padding-top: .625rem;
      padding-bottom: .9375rem;
    }
    ul {
      @include flexBox($alignItems: center, $justifyContent: space-between);
      width: 65%;
      li {
        @include font($fontFamily: $font-lato, $size: .875rem, $weight: 700);
        border: .0625rem solid transparent;
        border-bottom-color: $black;
        text-transform: uppercase;
        cursor: pointer;
        &.selected {
          color: $pink;
          border-bottom-color: $pink;
        }
        &.disabled {
          color: $boulder;
          pointer-events: none;
          border: 0;
        }
        &.active {
          background-color: $black;
          color: $white;
          padding: 0.1875rem 0.3125rem;
        }
      }
    }
    ul.filter-results-dropdown-menu {
      @include flexBox($flexDirection: column, $alignItems: flex-start);
      li {
        @include font($fontFamily: $font-lato, $size: 1rem, $weight: 700, $lh: 1.5);
        margin-bottom: .9375rem;
        border: 0;
        text-transform: none;
        &.selected {
          color: $pink;
        }
        &.inactive {
          color: $boulder;
          pointer-events: none;
          opacity: .5;
        }
      }
    }
    .search-results {
      .search {
        &-input {
          width: 100%;
        }
        &-text.error {
          @include font($fontFamily: $font-baskerville, $size: 1.375rem, $weight: 400, $lh: 1.45455);
        }
        &-bar-field {
          width: 100%;
        }
      }
      .alpha-list {
        display: none;
      }
    }
    .search-input {
      width: 35%;
      padding-right: .9375rem;
      input {
        @include font($fontFamily: $font-lato !important, $size: 1.125rem !important, $weight: 400, $lh: 1.44444 !important);
        &::placeholder {
          text-transform: capitalize;
          color: $boulder !important;
        }
      }
    }
    ul.filter-results-list {
      flex-basis: 100%;
      margin-top: 1.875rem;
      display: block;
      li {
        float: left;
        width: 25%;
        padding: 0 1.2rem;
        margin-bottom: .1875rem;
        border: 0;
        span {
          display: inline;
          @include font($fontFamily: $font-lato, $size: 1.125rem, $weight: 400, $lh: 1.44444);
          border-bottom: .0625rem solid #000;
          text-transform: none;
          transition: border-color .2s ease,background-color .2s ease,color .2s ease;
        }
        .close-icon {
          width: .625rem;
          height: .625rem;
          fill: $black;
          cursor: pointer;
          margin-left: .31rem
        }
        &.selected {
          color: $thunderbird;
          span {
            border: 0;
          }
        }
        &:hover, &.disabled {
          span {
            border-color: transparent;
          }
        }
        &.disabled {
          color: $boulder;
          opacity: 0.5;
          cursor: default;
        }
      }
    }
  }
}

@media screen and (max-width: $tablet-potrait) {
  .SearchFilters {
    .filters-mobile-cta {
      display: block !important;
      border: 1px solid;
    }
    .filters-container {
      display: none;
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: $white;
      z-index: 9999;
      &-title {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        height: 3.125rem;
        padding: .625rem .9375rem;
        border-bottom: .0625rem solid $boulder;
        > span {
          width: 33.33%;
          display: flex;
          align-items: center;
        }
        &-back, &-close {
          @include font($fontFamily: $font-lato, $size: 0.9rem, $weight: 700, $lh: 1.5);
          text-transform: uppercase;
          cursor: pointer;
        }
        &-back {
          justify-content: flex-start;
          visibility: hidden;
          pointer-events: none;
          &.active {
            visibility: visible;
            pointer-events: all;
          }
          &-icon {
            margin-right: .625rem;
            width: 1.125rem;
            height: 1.125rem;
            fill: $black;
          }
        }
        &-label {
          @include font($fontFamily: $font-baskerville, $size: 1.25rem, $lh: 1.4);
          font-size: 1.25rem;
          line-height: 1.4;
          justify-content: center;
        }
        &-close {
          justify-content: flex-end;
          &-icon {
            width: .75rem;
            height: .75rem;
            fill: $black;
            margin-left: .625rem;
            &.thick {
              stroke-width: 0.25rem;
            }
          }
        }
      }
      &.mobile-filters-visible {
        display: block;
      }
    }
    .filter-categories {
      flex-direction: column;
      padding: 0 .9375rem 1.875rem;
      height: calc(100vh - 4.5rem);
      overflow: auto;
      > li {
        width: 100%;
        border-left: 0;
        border-bottom: .0625rem solid $silver;
        padding: 1.875rem 0;
        margin: 0;
        > span:first-child {
          font-size: 0.75rem;
          line-height: 1.666;
        }
        > span:last-child {
          font-size: 2rem;
          list-style: 1.25;
          text-align: left;
          &:after {
            transform: rotateZ(270deg);
            margin: 0;
            position: absolute;
            top: .5rem;
            right: 0;
          }
        }
        &.clear-filters {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          background-color: $boulder;
          color: white;
          justify-content: center;
          display: flex;
          text-decoration: none;
          padding: .875rem 1.25rem;
          border: .0625rem solid transparent;
          margin: 0;
          &:not(.active) {
            cursor: none;
            pointer-events: none;
            &:hover {
              color: $white;
            }
          }
          &.active {
            background-color: $thunderbird;
            &:hover {
              color: $thunderbird;
              border-color: $thunderbird;
              background-color: $white;
            }
          }
        }
      }
    }
    .filter-results {
      margin-top: 0;
      padding: 0 .9375rem 1.875rem;
      > div {
        padding: 0;
      }
      .search-input {
        background-color: $white;
        width: 100%;
        padding: 1.325rem 0 .625rem;
        input {
          font-size: 1rem !important;
          line-height: 1.5 !important;
        }
      }
      .alpha-list {
        flex-direction: column;
        position: absolute;
        top: 0;
        height: calc(100% - 6.5rem);
        margin: 6.5rem 0 0;
        width: 1rem;
        right: .9375rem;
        overflow-y: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
        > li:first-child {
          display: none;
        }
      }
      ul {
        li.active {
          background-color: transparent;
          padding: 0;
          color: $black;
        }
      }
      ul.filter-results-list, ul.filter-results-dropdown-menu {
        width: 100%;
        overflow: auto;
        margin: 0 1.75rem 0 0;
        > li {
          width: 100%;
          padding: 1.25rem 0 1.25rem 2.1875rem;
          border-bottom: .0625rem solid $silver;
          margin-bottom: .3125rem;
          position: relative;
          &::before {
            content: '';
            position: absolute;
            display: block;
            left: 0;
            top: 1.375rem;
            width: 1.25rem;
            height: 1.25rem;
            border-radius: 100%;
            border: 0.0625rem solid $boulder;
          }
          &.selected {
            color: $black;
            &::after {
              content: '';
              position: absolute;
              display: block;
              left: 0.1875rem;
              top: 1.5625rem;
              width: 0.875rem;
              height: 0.875rem;
              border-radius: 100%;
              background-color: $black;
            }
          }
          &.disabled {
            opacity: 1;
            padding-left: 0;
            &::before, &::after {
              display: none;
            }
          }
          span {
            border: none;
            font-size: 1rem;
            line-height: 1.5;
            font-weight: 700;
          }
        }
      }
      ul.filter-results-list {
        height: calc(100vh - 9rem);
      }
      .search-results {
        .alpha-list {
          display: flex;
        }
        .filter-results-list:not(.search):not(.search-results-list) {
          display: none;
        }
      }
    }
  }
}
