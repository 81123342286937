@import "../../styles/base.scss";

.Header {
  @include flexBox($alignItems: center);
  font-family: $font-lato;
  position: sticky;
  top: 0;
  left: 0;
  background-color: $white;
  width: 100%;
  height: $header-height;
  transition: all .2s ease;
  box-shadow: 1px 3px 7px 0 hsla(0,0%,85%,.15);
  z-index: 1001;
  img {
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }
  &-nav {
    @include flexBox($alignItems: center, $justifyContent: space-between);
    max-width: $desktop-max-width;
    width: 100%;
    margin: 0 auto;
    padding: 0 30px;
    img {
      width: 20px;
      height: 22px;
      object-fit: contain;
    }
    &-menu {
      &-close, &-container {
        display: none !important;
      }
      &-container {
        position: fixed;
        top: $header-height;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        background-color: rgba(0,0,0,.6);
        z-index: 1001;
        text-align: center;
        justify-content: center;
        align-items: flex-start;
        margin: 0 auto;
        padding: 60px 30px;
        overflow: auto;
        > ul {
          @include flexBox($alignItems: center, $justifyContent: center, $flexDirection: column);
          > li {
            @include font($size: 24px, $color: $white, $weight: 300, $lh: 32px);
            padding: 14px 20px;
            text-transform: uppercase;
            cursor: pointer;
            a {
              text-decoration: none;
              color: $white;
            }
            &.Header-nav-menu  {
              &-search {
                margin-top: 60px;
              }
              &-bottom {
                padding: 8px 20px;
                @include font($size: 16px, $weight: normal, $lh: 24px, $ls: 0.48px);
                a, span {
                  @include flexBox($alignItems: center, $justifyContent: center);
                  img {
                    width: 25px;
                    height: 25px;
                    margin-right: 10px;
                  }
                }
              }
            }
          }
        }
      }
      &-item-subnav {
        @include flexBox($alignItems: center, $justifyContent: center, $flexDirection: column);
        margin: 20px auto;
        a {
          @include font($size: 16px, $weight: normal, $lh: 24px, $ls: 0.48px);
          display: inline-block;
          padding: 14px 20px;
          text-transform: none;
        }
      }
    }
  }
  &-menu-nav-trigger {
    > div {
      @include flexBox($alignItems: center, $justifyContent: center);
      @include font($size: 14px, $color: $black, $weight: 700, $lh: 21px);
      text-transform: uppercase;
      cursor: pointer;
      img {
        margin-right: 10px;
      }
    }
    .Header-nav-menu-close {
      color: $white;
    }
  }
  &-menu-home {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 259px;
    transition-duration: .4s;
    a {
      @include flexBox($alignItems: center, $justifyContent: center);
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  &-menu-secondary {
    @include flexBox($alignItems: center, $justifyContent: space-between);
    a, span {
      @include flexBox($alignItems: center, $justifyContent: center);
      @include font($size: 14px, $color: $black, $weight: 700, $lh: 21px);
      text-decoration: none;
      text-transform: uppercase;
      padding: 0 10px;
      cursor: pointer;
      img {
        margin-right: 10px;
      }
      &:first-child img {
        width: 25px;
        height: 25px;
      }
    }
    a:last-child {
      padding: 0 0 0 15px;
    }
    .account-menu {
      position: relative;
      height: $header-height;
      display: flex;
      align-items: center;
      &-container {

      }
      &-dropdown {
        position: absolute;
        top: $header-height;
        right: .5rem;
        background-color: $white;
        width: 100%;
        min-width: 12.5rem;
        visibility: hidden;
        opacity: 0;
        transition: all .2s ease;
        padding: .4rem .6rem;
        > li {
          display: flex;
          justify-content: flex-start;
          @include font($size: 14px, $color: $black, $weight: 700, $lh: 21px);
          text-transform: uppercase;
          cursor: pointer;
          > a {
            padding: 0;
          }
          &:not(:first-child) {
            margin-top: .5rem;
          }
        }
        &-arrow {
          height: .75rem;
          width: .75rem;
          display: block;
          transition: all .2s ease;
          fill: $black;
        }
      }
      &.hover, &.open {
        .account-menu-dropdown {
          visibility: visible;
          opacity: 1;
          &-arrow {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  &.menu-open {
    box-shadow: none;
    background-color: $black;
    z-index: 1001;
    .Header-nav-menu {
      &-close, &-container {
        display: flex !important;
      }
      &-toggle {
        display: none !important;
      }
    }
    img {
      filter: invert(100%);
    }
    a {
      color: $white;
    }
    .Header-menu-home {
      opacity: 1;
      pointer-events: all;
    }
    .Header-menu-secondary span {
      display: none;
    }
    &.hero-active {
      .Header-menu-home {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
  &.login-page-header {
    .Header-menu-nav-trigger, .Header-nav-menu-container, .Header-menu-secondary {
      display: none;
    }
    .Header-menu-home {
      opacity: 1;
    }
  }
  &.hero-active {
    .Header-menu-home {
      opacity: 0;
      pointer-events: none;
    }
  }
}

@media screen and (max-width: $tablet) {
  .Header {
    &-menu-secondary span {
      display: none;
    }
    &.hero-active {
      .Header-menu-home {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .Header {
    &-menu-home {
      width: 180px;
    }
    &-nav-menu-container {
      padding: 30px 15px;
      > ul > li {
        font-size: 20px;
        padding: 12px 20px;
      }
    }
    &-menu-nav-trigger {
      > div {
        font-size: 0;
        img {
          margin-right: 0;
        }
      }
    }
    &-menu-secondary a {
      font-size: 0;
      img {
        margin-right: 0;
      }
    }
  }
}
