@import "../../styles/base.scss";

.ServiceMessages {
  width: 100%;
  position: relative;
  background-color: $mexican-red;

  .ServiceMessage {
    @include font($fontFamily: $font-lato, $size: 1rem, $color: $white, $weight: 400, $lh: 1.5, $ls: .03rem);
    position: relative;
    width: 100%;
    padding: .625rem 4.5rem .625rem 1.5rem;
    &:after {
      content: '';
      display: block;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      border-bottom: 0.0625rem solid $white;
      opacity: 0.6;
    }
    .close {
      position: absolute;
      top: .625rem;
      right: 1.5rem;
      height: 1rem;
      cursor: pointer;
    }
  }
}
