@import "../../styles/base.scss";

.Carousel {
  max-width: $desktop-max-width;
  padding: 1.875rem;
  margin: 0 auto;
  &-header {
    @include flexBox($alignItems: center, $justifyContent: flex-end);
    border-bottom: 3px solid $white;
    margin-bottom: 2rem;
    h2 {
      @include font($fontFamily: $font-baskerville, $size: 2.375rem, $weight: 400, $ls: .09rem);
      margin: 0;
    }
    span {
      @include font($fontFamily: $font-lato, $size: 1.375rem, $weight: 400, $ls: .05rem);
      padding-top: .5rem;
      margin-left: auto;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
  .slick-slider {
    .slick-slide {
      a {
        text-decoration: none;
        pointer-events: none;
      }
      .Tile {
        display: flex;
        flex-direction: column;
        margin: 0 0.9rem;

        img {
          width: 100%;
          height: 100%;
        }
        &-body {
          background-color: $white;
          height: 9.5rem;
          &-pre-title:after {
            display: none;
          }
          &-header {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
        }
      }
    }
    .slick-arrow {
      z-index: 2;
      width: 6rem;
      height: 6rem;
      &:before, &:after {
        content: none;
      }
      &.slick-disabled {
        display: none !important;
      }
      &.slick-prev {
        left: -1.75rem;
      }
      &.slick-next {
        right: -1.75rem;
      }
    }
  }
}

.BroadcastPage {
  .Carousel {
    .slick-slider {
      .slick-slide {
        a {
          text-decoration: none;
          pointer-events: all;
        }
      }
    }
  }
}

.AudioBroadcastPage, {
  .Carousel {
    .slick-slider {
      .slick-slide {
        a {
          text-decoration: none;
          pointer-events: none;
        }
      }
    }
  }
}

@media screen and (max-width: $tablet-potrait) {
  .Carousel {
    &-header {
      h2 {
        font-size: 2rem;
        letter-spacing: normal;
      }
      span {
        font-size: 1rem;
      }
    }
    .slick-slider {
      .slick-slide .Tile {
        margin: 0 0.5rem;
      }
    }
  }
}
