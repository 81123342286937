@import "../../styles/base.scss";

.SubscribersOptions {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  &-container {
    margin-left: 1.875rem;
    width: fit-content;
    background-color: rgba(0,0,0,.6);
  }
  &-title {
    @include font($fontFamily: $font-lato, $size: 1rem, $color: $white, $lh: normal);
    padding: 1.875rem;
    display: block;
    cursor: pointer;
  }
  &-arrow {
    height: .875rem;
    width: .875rem;
    fill: $white;
    margin-left: .9375rem;
    transform: rotate(180deg);
    cursor: pointer;
  }
  &-options-list {
    padding: 0 1.875rem 1.875rem;
    > li {
      &:not(:first-child) {
        margin-top: .75rem;
      }
      & > span, & > a {
        @include font($fontFamily: $font-lato, $size: .75rem, $weight: 700, $color: $white, $lh: normal);
        text-decoration: none;
        text-transform: uppercase;
        border-bottom: 1px solid $white;
        cursor: pointer;
        transition: border-color .2s ease;
        &:hover {
          border-color: transparent;
        }
      }
    }
  }
  &.option-list-hidden {
    .SubscribersOptions-arrow {
      transform: none;
    }
    .SubscribersOptions-options-list {
      display: none;
    }
  }
}

@media screen and (max-width: $mobile) {
  .SubscribersOptions {
    position: static;
    padding: 0 .9375rem;
    &-container {
      border: 1px solid $silver;
      width: 100%;
      margin: 0;
      background-color: $white;
    }
    &-title {
      color: $black;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: .9375rem;
    }
    &-arrow {
      fill: $black;
    }
    &-options-list {
      padding: 0 .9375rem .9375rem;
      > li {
        & > span, & > a {
          color: $black;
          border-bottom: 1px solid $black;
        }
      }
    }
  }
}