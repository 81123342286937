@import "../../styles/base.scss";


.student-user {
  .Tile {
    &-item-free-preview {
      display: none;
    }
  }
}
.user {
  .Tile {
    &-item-free-preview {
      display: none;
    }
  }
}

.Tile {
  @include flexBox($flexDirection: column, $justifyContent: space-between);
  height: 100%;
  &-image-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%!important;
    background-color: rgba(0,0,0,.8);
    img {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
    &-overlay {
      position: absolute;
      bottom: 1.875rem;
      left: 1.875rem;
      color: $white;
      p {
        @include font($fontFamily: $font-lato, $size: 1rem, $weight: 700, $lh: 1.5, $ls: .03rem);
        text-transform: uppercase;
        margin: 0;
        &:last-child {
          @include font($fontFamily: $font-baskerville, $size: 1.75rem, $weight: 400, $lh: 1.28571, $ls: normal);
          text-transform: none;
        }
      }
    }
    &.audio {
      &-1930s {
        background-color: $nevada;
      }
      &-1940s {
        background-color: $razzmatazz;
      }
      &-1950s {
        background-color: $fern-frond;
      }
      &-1960s {
        background-color: $rose-of-sharon;
      }
      &-1970s {
        background-color: $buttered-rum;
      }
      &-1980s {
        background-color: $blue-lagoon;
      }
      &-1990s {
        background-color: $siren;
      }
      &-2000s {
        background-color: $catalina-blue;
      }
      &-2010s {
        background-color: $killarney;
      }
      &-2020s {
        background-color: $bismark;
      }
    }
  }
  &-item-mediatype-icon {
    @include flexBox($flexDirection: column, $alignItems: center, $justifyContent: center);
    position: absolute;
    top: 0;
    left: 0;
    height: 3.75rem;
    width: 3.75rem;
    padding: .46875rem;
    background-color: rgba(0,0,0,.6);
    img {
      height: 2.3125rem;
      width: 2.3125rem;
      object-fit: contain;
    }
  }
  &-item-free-preview {
    position: absolute;
    top: .5rem;
    left: .5rem;
    width: 100%;
    z-index: 2;
    img {
      width: 8rem !important;
      height: 1.56rem !important;
      object-fit: contain;
    }
  }
  &-marker {
    @include font($fontFamily: $font-lato, $size: 1rem, $color: $black, $lh: 1.5, $ls: .03rem);
    position: absolute;
    bottom: 1.875rem;
    z-index: 20;
    margin: 0;
    span {
      display: inline-block;
      background-color: $white;
      padding: .1875rem .625rem;
      border-radius: .1875rem;
    }
    &.left {
      left: 1.875rem;
    }
    &.right {
      right: 1.875rem;
      span {
        margin-left: 0.56rem;
      }
    }
  }
  &-header {
    @include flexBox($alignItems: center);
    background-color: $black;
    margin-bottom: 1rem;
    span {
      @include font($fontFamily: $font-lato, $size: .875rem, $color: $white, $weight: 700, $lh: .03rem);
    }
  }
  &-body {
    @include flexBox($flexDirection: column, $alignItems: flex-start, $justifyContent: space-between);
    padding: 1.875rem;
    &-metadata {
      position: relative;
      width: 100%;
      min-height: 20px;
      span {
        @include font($fontFamily: $font-lato, $size: 0.75rem, $weight: 700, $lh: 1.66667);
        text-transform: uppercase;
      }
      &.has-medatadata .Tile-body-pre-title {
        &:after {
          content: "\2758";
          color: $boulder;
          margin: 0 .625rem;
        }
      }
      &:empty {
        display: none;
      }
    }
    &-pre-title{
      color: $black;
    }
    &-performance-date {
      color: $boulder;
    }
    &-badge {
      position: absolute;
      right: 0;
      color: $pink;
    }
    &-header {
      @include font($fontFamily: $font-baskerville, $size: 1.75rem, $weight: 400, $style: italic, $color: $black, $lh: 1.28571);
      margin: 0.3125rem 0 0;
      word-break: break-word;
    }
    &-cast {
      @include font($fontFamily: $font-lato, $size: 1.125rem, $color: $boulder, $lh: 1.44444);
      margin-top: .625rem;
    }
  }
  &-cta {
    padding: 1.875rem;
    margin-top: auto;
  }
  &-progress {
    width: 100%;
    height: .3125rem;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.2);

    &-bar {
      height: 100%;
      background-color: $pink;
    }
  }
  .playing-now-btn {
    @include flexBox($alignItems: center, $justifyContent: center);
    height: 1.81rem;
    text-transform: uppercase;
    margin-left: 1.2rem;
    padding: 0.5rem 1rem;
    background-color: $pink;
    opacity: 0;
  }
  &.playing-now {
    border-bottom: 3px solid $pink;
    cursor: pointer;
    .Tile-body, .Tile-image-container {
      border: 3px solid $pink;
      border-bottom: 0;
    }
    .Tile-body {
      border-top: 0;
    }
    .playing-now-btn {
      opacity: 1;
    }
  }
  &.person {
    .Tile-body-pre-title:after {
      display: none;
    }
  }
  &.short-form {
    .Tile {
      &-body-header {
        font-style: normal;
        margin-top: 0;
      }
    }
  }
  &.broadcast {
    .Tile {
      &-body {
        &-header {
          width: calc(100% - 2rem);
        }
        &-metadata {
          min-height: auto;
        }
        &-badge {
          top: 0.3125rem;
        }
      }
    }
  }
}


@media screen and (max-width: $desktop-max-width) {
  .Tile {
    &-body {
      &-cast {
        font-size: .875rem;
        line-height: 1.5;
      }
    }
  }
}

@media screen and (max-width: $desktop-small) {
  .Tile {
    &-body, &-cta {
      padding: 1.25rem;
    }
  }
}

@media screen and (max-width: $tablet) {
  .Tile {
    &-cta {
      margin-top: 1.25rem !important;
    }
  }
}

@media screen and (max-width: $tablet-potrait) {
  .Tile {
    &-header {
      justify-content: space-between;
      .playing-now-btn {
        margin-left: 0;
      }
    }
    &-body-header {
      font-size: 1.5rem;
      line-height: 1.33333;
    }
  }
}

@media screen and (max-width: $mobile) {
  .Tile {
    &-header {
      justify-content: flex-start;
      .playing-now-btn {
        margin-left: 1.2rem;
      }
    }
  }
}
