@import "../../styles/base.scss";

.PersonInfo {
  @include flexBox($justifyContent: center, $alignItems: flex-start);
  .person {
    &-image {
      width: auto;
      height: auto;
      margin-bottom: 5rem;
      img {
        width: 20.25rem;
        height: 20.25rem;
        object-fit: cover;
        object-position: center center;
      }
    }
    &-info {
      margin-left: 3.75rem;
      h2 {
        @include font($fontFamily: $font-baskerville, $size: 2.5rem, $weight: 400, $ls: .06rem);
        margin: 0 0 .375rem;
      }
      p {
        @include font($fontFamily: $font-lato, $size: 1rem, $weight: 700, $ls: .03rem);
        margin: 0 0 2.18rem;
        text-transform: uppercase;
        &:last-child {
          @include font($fontFamily: $font-baskerville, $size: 1.37rem, $weight: 400, $lh: 1.59, $ls: .03rem);
          text-transform: none;
          margin: 0;
        }
      }
    }
  }
}

@media screen and (max-width: $tablet-potrait) {
  .PersonInfo {
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    .person {
      &-image {
        margin-bottom: 1rem;
      }
      &-info {
        margin: 0 auto;
      }
    }
  }
}

