@import "./fonts.scss";

// Colors
$black: #000000;
$white: #FFFFFF;
$mine-shaft: #323232;
$boulder: #767676;
$thunderbird: #C61C1C;
$silver: #CCCCCC;
$pink: #e3005b;
$scorpion: #5e5e5e;
$nevada: #707174;
$razzmatazz: #c90c58;
$fern-frond: #5a8126;
$rose-of-sharon: #c34c04;
$buttered-rum: #996f09;
$blue-lagoon: #006f88;
$siren: #810055;
$catalina-blue: #083a81;
$killarney: #417142;
$bismark: #446a7d;
$concrete: #f2f2f2;
$facebook-blue: #375493;
$twitter-blue: #00aded;
$mexican-red: #aa2525;
$gray: #919191;
$gray25: #c9c9c9;


$font-lato:  'Lato', Helvetica, Arial, sans-serif;
$font-baskerville: 'BaskervilleMTStd', 'Times New Roman';

$header-height: 60px;
$desktop-max-width: 1600px;
$desktop-medium: 1440px;
$desktop-small: 1280px;
$tablet: 1024px;
$tablet-potrait: 768px;
$mobile: 640px;
$small-mobile: 480px;

$desktop-margin: 30px;


@mixin font($fontFamily: false, $size: false, $color: false, $weight: false,  $style: false, $lh: false, $ls: false) {
  @if $fontFamily { font-family: $fontFamily; }
  @if $size { font-size: $size; }
  @if $color { color: $color; }
  @if $weight { font-weight: $weight; }
  @if $style { font-style: $style; }
  @if $lh { line-height: $lh; }
  @if $ls { letter-spacing: $ls; }
}

@mixin flexBox($display: false, $flexDirection: false, $flexFlow: false, $alignItems: false, $justifyContent: false, $flexWrap: false, $flex: false) {
  @if $display { display: $display } @else { display: flex }
  @if $flexDirection { flex-direction: $flexDirection; }
  @if $flexWrap { flex-wrap: $flexWrap; }
  @if $flexFlow { flex-flow: $flexFlow; }
  @if $alignItems { align-items: $alignItems; }
  @if $justifyContent { justify-content: $justifyContent; }
  @if $flex { flex: $flex; }
}

@mixin Button($color: false, $borderColor: false, $backgroundColor: false) {
  @if $color { color: $color !important }
  @if $borderColor { border-color: $borderColor !important }
  @if $backgroundColor { background-color: $backgroundColor !important }
  &:hover {
    color: $backgroundColor !important;
    background-color: $color !important;
  }
}

@mixin image($width: false, $height: false, $of: false) {
  @if $width { width: $width; }
  @if $height { height: $height; }
  @if $of { object-fit: $of; }
}

@mixin captionBorder($color: $black, $size: 1px) {
  text-shadow:
    -#{$size} -#{$size} 0 $color,
     0        -#{$size} 0 $color,
     #{$size} -#{$size} 0 $color,
     #{$size}  0        0 $color,
     #{$size}  #{$size} 0 $color,
     0         #{$size} 0 $color,
    -#{$size}  #{$size} 0 $color,
    -#{$size}  0        0 $color;
}

div:focus, button:focus {
  // outline: none !important;
}

html, body, #root {
  border: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: auto;
}

body {
  font-size: 16px;
  margin: 0;
  & ::selection {
    background-color: $thunderbird;
    color: $white;
  }
  &.header-nav-open {
    overflow: hidden;
    #app-header {
      display: none;
    }
    .app-body {
      filter: blur(0.9375rem);
    }
  }
  &.modal-open {
    overflow: hidden;
  }
}

h3 {
  font-size:  16px;
}

ul,li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.app-layout {
  position: relative;
  min-height: 100vh;
  max-width: 100%;
  margin:0 auto;
}

.app-body {
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
  > div {
    height: 100%;
  }
}

.ellipsis-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hide {
  display: none !important;
}

.btn {
  display: inline-flex !important;
  justify-content: center;
  border-radius: 0 !important;
  color: $white !important;
  height: 50px !important;
  padding: 14px 20px !important;
  margin-left: 0 !important;
  transition: border-color .2s ease,background-color .2s ease !important;
  &:hover {
    background-color: $white !important;
    transform: none !important;
  }
  span {
    @include font($fontFamily: $font-lato, $size: 14px, $weight: 700, $lh: 21px);
    display: inline-block;
    text-transform: uppercase;
  }
  &-red {
    @include Button ($color: $white, $borderColor: $thunderbird, $backgroundColor: $thunderbird);
  }
  &-pink {
    @include Button ($color: $white, $borderColor: $pink, $backgroundColor: $pink);
  }
  &-transparent {
    &.white {
      @include Button ($color: $white, $borderColor: $white, $backgroundColor: transparent);
      &:hover {
        color: $black !important;
        background-color: $white !important;
      }
    }
    &.black {
      @include Button ($color: $black, $borderColor: $black, $backgroundColor: transparent);
      &:hover {
        color: $white !important;
        background-color: $black !important;
      }
    }
    &.red {
      @include Button ($color: $thunderbird, $borderColor: $thunderbird, $backgroundColor: transparent);
      &:hover {
        color: $white !important;
        background-color: $thunderbird !important;
      }
    }
    &.margin-left-1 {
      margin-left: 1rem !important;
    }
  }
  &-clear {
    border: 0 !important;
    background-color: transparent !important;
    color: $black !important;
  }
  &-load-more {
    width: fit-content;
    margin: 0 auto !important;
    display: block !important;
    span {
      @include font($fontFamily: $font-lato, $size: .875rem, $weight: 700, $lh: 1.5, $color: $boulder);
    }
    &:hover {
      background-color: transparent !important;
      span {
        color: $thunderbird;
      }
    }
  }
}

.back-btn {
  @include font($fontFamily: $font-lato, $size: 1rem, $weight: 700, $lh: 1.5, $ls: .03rem);
  @include flexBox($alignItems: center, $justifyContent: center);
  cursor: pointer;
  text-transform: uppercase;
  margin-bottom: 4rem;
  img {
    width: .875rem;
    height: 1.25rem;
    margin-right: .5rem;
  }
}

.accordion-button {
  position: absolute;
  right: 0;
  span {
    display: none;
    position: relative;
    height: .875rem;
    width: .875rem;
    &:before, &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      background-color: $boulder;
      transform: translateY(-50%);
    }
    &:before {
      width: 100%;
      height: .125rem;
    }
    &:after {
      width: .125rem;
      height: 100%;
      right: 50%;
      transform: translateY(-50%) translateX(50%);
      transition: all .2s ease;
    }
  }
}

@media screen and (max-width: $mobile) {
  .app-body, .app-layout {
    min-height: 100%;
  }
  .btn-load-more {
    border: .0625rem solid $black !important;
    margin: .9375rem auto !important;
    width: 100% !important;
    span {
      color: $black;
    }
    &:hover {
      background-color: $black !important;
      span {
        color: $white;
      }
    }
  }
}

.cursor-default {
  cursor:default
}
