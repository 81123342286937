@import "../../styles/base.scss";

.Player {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 50;
  .video-hover {
    pointer-events: all;
    cursor: pointer;
    &-catalog {
      @include flexBox();
      text-decoration: none;
      span {
        margin-left: 0.25rem;
      }
    }
  }
  .player-overlay-end-screen {
    position: absolute;
    top: 0;
    color: $white;
    z-index: 350;
    width: 100%;
    height: 100%;
    text-align: center;
    .buy-ticket-cta {
      position: absolute;
      top: 1.5rem;
      right: 6.25rem;
      opacity: 1;
      border: 1px solid $white !important;
      span {
        @include flexBox($alignItems: center, $justifyContent: center);
      }
      svg {
        width: 1.375rem;
        height: 1.375rem;
        font-size: .55rem;
        margin-left: .5rem;
        fill: $white;
      }
      &:hover svg {
        fill: $black;
      }
    }
    .video-end-details {
      position: relative;
      top: 20%;
      .btn {
        @include flexBox($display: flex !important, $alignItems: center, $justifyContent: center);
        @include font($fontFamily: $font-lato, $size: 0.875rem, $lh: 1.31rem);
        width: 16.875rem !important;
        margin: 0 auto .625rem !important;
        height: auto !important;
        white-space: pre-wrap;
        &-transparent {
          border: 1px solid rgba(255, 255, 255, 0.3) !important;
        }
      }
      p {
        @include font($fontFamily: $font-lato, $size: 0.875rem, $weight: 400, $lh: .625rem);
        margin: 2.5rem 0 .625rem;
        span {
          text-decoration: underline;
        }
        &:first-child {
          @include font($fontFamily: $font-baskerville, $size: 1.875rem, $weight: 400, $lh: 2.06rem);
          margin: 0 0 1.25rem;
        }
      }
    }
    .Grid {
      position: relative;
      top: 20%;
      padding: 0 3.25rem;
      &-header-wrapper {
        text-align: center;
        padding-bottom: 1rem;
        h2 {
          font-size: 1.875rem;
          &:after {
            content: " ...";
            position: relative;
          }
        }
      }
      &-contents {
        a {
          border: 0;
          .Tile {
            button {
              display: none !important;
            }
          }
        }
      }
    }
  }

  #player.logged-out {
    margin-bottom: 60px;
    .vjs-big-play-button {
      display: none !important;
    }
    .video-end.logged-out {
      display: block !important;
    }
  }

  .vjs-text-track-cue div {
    color: $gray25 !important;
    background-color: transparent !important;
    @include captionBorder;
  }
}

@media screen and (max-width: $tablet-potrait) {
  .Player {
    #player.logged-out {
      margin-bottom: 80px;
    }
  }
}

@media screen and (max-width: $mobile) {
  .hidden-mobile {
    display: none !important;
  }
  .Player {
    #player.logged-out {
      margin-bottom: 30px;
      .buy-ticket-cta {
        display: none !important;
      }
    }
  }
}


.bcc-audio-description-button.bcc-disabled span, .bcc-subtitles-button.bcc-disabled span {
  text-decoration: line-through;
}