@import "../../styles/base.scss";

.Footer {
  position: relative;
  background-color: $black;
  color: $white;
  font-family: $font-lato;
  &-content-wrapper {
    @include flexBox($alignItems: center, $justifyContent: space-around);
    padding: 30px;
    margin: 0 auto;
    max-width: $desktop-max-width;
  }
  &-social, &-legal, &-sponsors {
    padding: 0 15px;
  }
  &-social {
    @include flexBox($alignItems: center, $justifyContent: center);
    p {
      margin: 0;
      .email-signup {
        @include font($size: 14px, $color: $white, $weight: 700, $lh: 1.5);
        display: inline-block;
        border: 1px solid $white;
        cursor: pointer;
        padding: 14px 20px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        &:hover {
          color: $black;
          background-color: $white;
        }
      }
    }
    ul {
      @include flexBox($alignItems: center, $justifyContent: center);
      margin-left: 30px;
      li a {
        @include flexBox($alignItems: center, $justifyContent: center);
        position: relative;
        width: 45px;
        height: 45px;
        &:before {
          position: absolute;
          top: 0;
          left: 0;
          content: "";
          background-color: $white;
          border-radius: 100%;
          width: calc(100% - 20px);
          height: calc(100% - 20px);
          margin: 10px;
          z-index: 10;
          transition: all .2s ease;
        }
        img {
          position: relative;
          width: 15px;
          height: 15px;
          z-index: 20;
        }
        &:hover {
          text-decoration: none;
          &:before {
            background-color: $black;
          }
          img {
            filter: invert(100%);
          }
        }
      }
    }
  }
  &-legal {
    @include flexBox($flexDirection: column, $justifyContent: center);
    ul {
      @include flexBox($alignItems: flex-start, $flexWrap: wrap);
      li {
        margin-right: 20px;
        padding-bottom: 5px;
        a {
          @include font($size: 14px, $color: $white, $weight: 700, $lh: normal);
          display: inline-block;
          border-bottom: 1px solid transparent;
          text-decoration: none;
          text-transform: uppercase;
          transition: border-color .2s ease,background-color .2s ease,color .2s ease;
          &:hover {
            border-color: $white;
            text-decoration: none;
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    p {
      @include font($size: 14px, $color: $white, $weight: 400, $lh: 1.5);
      margin: 0;
    }
  }
  &-sponsors {
    ul {
      @include flexBox($alignItems: center, $justifyContent: center);
      li:last-child {
        margin-left: 30px;
      }
    }
  }
  &-divider {
    background-color: $white;
    width: 1px;
    height: 100px;
    opacity: 0.5;
  }
}

@media screen and (max-width: $desktop-small) {
  .Footer {
    &-social {
      flex-basis: 40%;
    }
    &-legal {
      flex-basis: 55%;
    }
    &-content-wrapper {
      flex-wrap: wrap;
    }
    &-sponsors {
      flex-basis: 100%;
      padding-top: 30px;
    }
    &-divider:nth-child(4) {
      display: none;
    }
  }
}

@media screen and (max-width: $tablet) {
  .Footer {
    &-social {
      flex-basis: 100%;
    }
    &-legal {
      align-items: center;
      flex-basis: 100%;
      padding-top: 30px;
      ul {
        align-items: center;
        justify-content: center;
      }
    }
    &-divider {
      display: block !important;
      width: 100%;
      margin-top: 30px;
      height: 1px;
    }
  }
}

@media screen and (max-width: $mobile) {
  .Footer {
    &-content-wrapper {
      padding: 1.875rem 0.9375rem;
    }
    &-social, &-legal, &-sponsors {
      padding-left: 0;
      padding-right: 0;
    }
    &-social {
      width: 100%;
      flex-direction: column;
      p {
        margin-top: .9375rem;
        width: 100%;
        a {
          width: 100%;
          text-align: center;
        }
      }
      ul {
        margin: 1.875rem auto 0;
      }
    }
  }
}

