@import "../../styles/base.scss";

.ShortFormPerformanceInfo {
  @include flexBox(
    $display: flex,
    $alignItems: flex-start
  );
  max-width: $desktop-max-width;
  width: 100%;
  padding: 2.5rem 1.875rem 0;
  margin: 0 auto;
  .performance {
    &-info {
      width: 60.12987%;
      margin-bottom: 1.875rem;
    }
    &-title {
      @include font(
        $fontFamily: $font-baskerville,
        $size: 2.5rem,
        $weight: 400,
        $lh: 1.2
      );
      font-style: normal;
      word-break: break-word;
      margin: 0 0 1.875rem;
    }
    &-description {
      @include font(
        $fontFamily: $font-lato,
        $size: 1.125rem,
        $weight: 400,
        $lh: 1.44444
      );
      width: 100%;
      margin: 0;
    }
    &-ctas {
      margin-left: auto;
      @include flexBox(
        $display: flex,
        $flexDirection: column,
        $alignItems: flex-end
      );
      gap: 2rem 0;
      span {
        @include flexBox($alignItems: center, $justifyContent: center);
      }
      #icon-calendar-grid {
        width: 1.375rem;
        height: 1.375rem;
        font-size: .55rem;
        margin-left: .5rem;
        fill: $white;
      }
      a:hover #icon-calendar-grid {
        fill: $black;
      }
    }
    &-actions {
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: $tablet-potrait) {
  .ShortFormPerformanceInfo {
    flex-direction: column;
    padding: 1.25rem .9375rem 0;
    .performance {
      &-info {
        width: 100%;
      }
      &-title {
        line-height: 1;
        margin-bottom: 1.5rem;
      }
      &-ctas {
        flex-direction: row;
        align-items: center;
        gap: 0 2rem;
        margin: 0;
      }
    }
  }
}
