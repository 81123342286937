@import "../../styles/base.scss";

.PersonPage {
  max-width: $desktop-max-width;
  margin: 0 auto;
  padding: 1.2rem $desktop-margin;
  .Grid {
    &-header {
      @include font($fontFamily: $font-baskerville, $size: 2.5rem, $weight: 400, $ls: .06rem);
      margin-bottom: 2.25rem;
    }
  }
}
