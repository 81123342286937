@import "../../styles/base.scss";

.PerformanceInfo {
  max-width: $desktop-max-width;
  width: 100%;
  padding: 1.875rem;
  margin: 0 auto;
  .accordion {
    &-header {
      display: none;
    }
  }
  h2 {
    @include font($fontFamily: $font-baskerville, $size: 3rem, $weight: 400, $lh: 1.16667);
    margin: 0 0 .9375rem;
  }
  .performance-header {
    @include flexBox($justifyContent: space-between);
    position: relative;
    &-info {
      width: 60.12987%;
      margin-bottom: 3.125rem;
    }
  }
  .performance {
    &-full-synopsis-url {
      display: none;
    }
    &-header {
      @include flexBox($justifyContent: space-between);
      &-info {
        width: 60.12987%;
        margin-bottom: 3.125rem;
      }
      &-cta {
        span {
          @include flexBox($alignItems: center, $justifyContent: center);
        }
        svg {
          width: 1.375rem;
          height: 1.375rem;
          font-size: .55rem;
          margin-left: .5rem;
          fill: $white;
        }
        a:hover svg {
          fill: $black;
        }
      }
    }
    &-body {
      @include flexBox();
      margin-bottom: 1.875rem;
      &-cta a {
        width: 10.75rem;
        letter-spacing: .038rem;
        margin: 2rem 2.5rem 5rem 0;
        span {
          @include flexBox($alignItems: center, $justifyContent: center);
        }
      }
      > .performance-actions {
        display: none;
      }
    }
    &-composer {
      @include font($fontFamily: $font-lato, $size: 1rem, $weight: 400, $lh: 1.5, $ls: .03rem);
      text-transform: uppercase;
    }
    &-title {
      @include font($fontFamily: $font-baskerville, $size: 4.5rem, $weight: 400, $style: italic, $lh: 0.9);
      margin: 0;
      overflow-wrap: break-word;
    }
    &-date-time {
      @include font($fontFamily: $font-lato, $size: 1rem, $weight: 700, $lh: 1.5, $ls: .03rem);
      margin-bottom: 0;
      .separator {
        margin: 0 .5rem
      }
    }
    &-description {
      @include font($fontFamily: $font-baskerville, $size: 1.75rem, $weight: 400, $lh: 1.28571);
      margin: 0;
      width: 100%;
      padding-right: 3.75rem;
    }
    &-metadata {
      .performance-share-trigger {
        p {
          display: flex;
          align-items: center;
        }
      }
      p {
        @include font($fontFamily: $font-lato, $size: 1rem, $weight: 700, $lh: 1.5, $ls: .03rem);
        margin: 0 0 1.25rem;
        span {
          display: block;
          text-transform: uppercase;
          color: $white;
          &:first-child {
            font-weight: 400;
            color: $silver;
          }
        }
      }
      &:empty {
        display: none;
      }
    }
    &-cast {
      &-header {
        display: block;
      }
      ul {
        @include flexBox($flexWrap: wrap);
        width: 100%;
        li {
          width: 33%;
          margin-bottom: 1.25rem;
          span {
            @include font($fontFamily: $font-lato, $size: 1rem, $color: $silver, $weight: 400, $lh: 1.5, $ls: .03rem);
            display: block;
            &:first-child {
              text-transform: uppercase;
            }
            &:last-child {
              @include font($fontFamily: $font-baskerville, $size: 1.5625rem, $color: $white, $weight: 400, $lh: 1.33333);
            }
          }
        }
      }
    }
    &-group-name {
      font-size: .875rem !important;
      font-weight: 400 !important;
    }
  }
}

@media screen and (max-width: $desktop-medium) {
  .PerformanceInfo {
    .performance {
      &-title {
        font-size: 3.5rem;
      }
    }
  }
}

@media screen and (max-width: $tablet) {
  .PerformanceInfo {
    .performance {
      &-header-cta {
        display: none;
      }
      &-header-info {
        width: 100%;
        margin-bottom: 2rem;
      }
    }
    &.broadcast {
      .performance {
        &-header {
          &-info {
            width: 80%;
          }
          &-cta {
            display: block;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $tablet-potrait) {
  .PerformanceInfo {
    padding: 0.9375rem 0.9375rem 1.875rem;
    .performance {
      &-title {
        font-size: 2.5rem;
      }
      &-description {
        font-size: 1.25rem;
      }
      &-cast {
        ul li span:last-child {
          font-size: 1.25rem;
        }
      }
    }
    h2 {
      font-size: 1.5rem;
    }
    &.broadcast {
      padding-top: 1.875rem;
    }
  }
}

@media screen and (max-width: $mobile) {
  .PerformanceInfo {
    padding-bottom: 0;
    .performance {
      &-full-synopsis-url {
        display: block;
        margin: 3rem 0;
      }
      &-header-info {
        width: 100%;
      }
      &-body {
        display: block;
        margin: 1rem 0 0;
        .performance-actions {
          display: flex;
        }
        &-cta {
          display: none;
        }
      }
      &-metadata {
        .performance-actions {
          display: none;
        }
      }
      &-title {
        font-size: 2.5rem;
      }
      &-description {
        width: 100%;
        font-size: 1.25rem;
        padding-right: 0;
        margin-bottom: 1.875rem;
      }
      &-cast {
        ul {
          display: none;
          flex-direction: column;
          li {
            width: 100%;
          }
        }
      }
    }
    .accordion {
      &-header {
        @include flexBox($alignItems: center, $justifyContent: space-between);
        position: relative;
        width: 100%;
        padding: 1.25rem 1.875rem 1.25rem 0;
        border-top: .0625rem solid $silver;
        cursor: pointer;
        pointer-events: all;
        h2 {
          margin: 0;
        }
      }
      &-body {
        display: none;
      }
      &-button {
        span {
          display: block;
          cursor: pointer;
        }
      }
      &.expanded {
        .accordion-button span:after {
          opacity: 0;
        }
        .accordion-body {
          display: block;
        }
      }
    }
    &.broadcast {
      .performance {
        &-header {
          flex-direction: column;
          &-info {
            width: 100%;
            margin-bottom: 1.5rem;
          }
          &-cta {
            margin-bottom: 3rem;
          }
        }
        &-body-cta {
          display: block;
          .btn-full-synopsis-url {
            display: none !important;
          }
          a {
            margin: 1rem 0;
          }
        }
        &-metadata-header {
          display: none;
          h2 {
            display: none;
          }
        }
      }
      .accordion {
        &-header {
          border: 0;
        }
        &-button {
          display: none;
        }
        &-body {
          display: block;
        }
      }
    }
  }
}
