@import "../../styles/base.scss";

.Broadcast-content {
  &.broadcast-ending {
    .hasEnded {
      .broadcast-ended-message {
        display: flex;
      }
    }
  }
  .no-broadcasts-message {
    @include flexBox($alignItems: center, $justifyContent: center, $flexDirection: column);
      @include font($fontFamily: $font-baskerville, $size: 2.5rem, $weight: 400, $ls: .07rem);
      width: 100%;
      height: 15rem;
      margin-bottom: 6rem;
      text-align: center;
  }

  .Carousel .slick-slider .slick-slide {
    .Tile-body {
      min-height: 9.5rem;
      height: auto;
    }
  }
}
.BroadcastPage {
  .vjs-audio-countdown {
    display: none;
  }
  display:flex;
  flex-direction: column;
  .video-hover {
    display:none;
  }
  
  
}
  
.BroadcastPage:hover {
  .video-hover {
    position: absolute;
    top: 24px;
    left: 48%;
    z-index: 1;
    display:block;
    opacity: 1;
    width: 0;
    svg {
        position: relative;
        top: 1px;
        left: -2px;
        fill: #fff;
        height: 14px;
        width: 20px;
    }
  }   
}
