@import "../../styles/base.scss";

.VideoAndAudioCatalog {
  padding-top: 3.75rem;
  margin-top: 3.75rem;
  &-header {
    @include font($fontFamily: $font-baskerville, $size: 3rem, $weight: 400, $lh: 1.16667);
    margin: 0 0 1.875rem;
  }
  &-filter-results {
    margin-top: 1.875rem;
    h3 {
      @include font($fontFamily: $font-lato, $size: 1rem, $weight: 700, $lh: 1.5, $ls: .03rem);
      text-transform: uppercase;
      margin-bottom: 1.875rem;
    }
    .Grid {
      &-header h2 {
        @include font($size: 2.5rem, $weight: 400, $style: italic, $lh: 1.2);
      }
    }
  }
}

@media screen and (max-width: $tablet-potrait) {
  .VideoAndAudioCatalog {
    &-header {
      font-size: 2rem;
      line-height: 1.25;
    }
    .Grid-header h2 {
      font-size: 1.75rem;
      line-height: 1.28571;
    }
  }
}

@media screen and (max-width: $mobile) {
  .VideoAndAudioCatalog {
    margin-top: 0;
    border-top: .0625rem solid $silver;
    padding-top: 1.25rem;
  }
}
