@import "../../styles/base.scss";

.Loading-indicator {
  @include flexBox($alignItems: center, $justifyContent: flex-start);
  margin-top: 1rem;
  &.Loading-overlay {
    pointer-events: none;
    position: absolute;
    top: -10px;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    margin-top: 0;
  }
  .spinner {
    width: 1.5625rem;
    height: 1.5625rem;
    border: .1875rem solid $concrete;
    border-top: .1875rem solid $black;
    border-radius: 50%;
    margin: 0 auto;
    animation: spin 3s linear infinite;
  }
  &.Loading-label {
    @include font($fontFamily: $font-lato, $size: 1rem, $weight: 700, $ls: .03rem, $lh: 1.5);
    text-transform: uppercase;
    .spinner {
      margin: 0 1rem 0 0;
    }
  }
  &.Loading-centered {
    justify-content: center;
  }
  @keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}
}
