@font-face {
  font-family: "BaskervilleMTStd";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/BaskervilleMTStd.eot");
  src: url("/assets/fonts/BaskervilleMTStd.eot?#iefix") format("embedded-opentype"),url("/assets/fonts/BaskervilleMTStd.woff2") format("woff2"),url("/assets/fonts/BaskervilleMTStd.woff") format("woff"),url("/assets/fonts/BaskervilleMTStd.ttf") format("truetype");
}

@font-face {
  font-family: "BaskervilleMTStd";
  font-style: italic;
  font-weight: 400;
  src: url("/assets/fonts/BaskervilleMTStdItalic.eot");
  src: url("/assets/fonts/BaskervilleMTStdItalic.eot?#iefix") format("embedded-opentype"),url("/assets/fonts/BaskervilleMTStdItalic.woff2") format("woff2"),url("/assets/fonts/BaskervilleMTStdItalic.woff") format("woff"),url("/assets/fonts/BaskervilleMTStdItalic.ttf") format("truetype");
}

@font-face {
  font-family: "BaskervilleMTStd";
  font-style: normal;
  font-weight: 700;
  src: url("/assets/fonts/BaskervilleMTStdBold.eot");
  src: url("/assets/fonts/BaskervilleMTStdBold.eot?#iefix") format("embedded-opentype"),url("/assets/fonts/BaskervilleMTStdBold.woff2") format("woff2"),url("/assets/fonts/BaskervilleMTStdBold.woff") format("woff"),url("/assets/fonts/BaskervilleMTStdBold.ttf") format("truetype");
}
