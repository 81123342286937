@import "../../styles/base.scss";

.slide-overlay-info {
  @include flexBox($display: flex, $flexDirection: row, $justifyContent: flex-start, $alignItems: flex-start);
  width: 70%;
  padding: $desktop-margin 40px;
  background-color: rgba(0, 0, 0, 0.6);
  &-wrapper {
    bottom: 0;
    max-width: $desktop-max-width;
    width: 100%;
    margin: 0 auto;
    padding: $desktop-margin;
    z-index: 2;
    display: block;
    opacity: 0;
    transition: opacity .4s;
    position: absolute;
    z-index: 1000;
    pointer-events: none;
    &.active {
      position: fixed;
      margin-bottom: calc(#{$desktop-margin} * 1.5);
    }
  }
  .login-ctas {
    @include flexBox($display: flex, $flexDirection: column, $alignItems: flex-start, $justifyContent: center);
    position: relative;
    margin-right: $desktop-margin * 2;
    .btn {
      width: 209px;
      margin-bottom: 15px;
    }
    a {
      @include font($fontFamily: $font-lato, $size: 14px, $weight: 400, $color: $white, $lh: 21px);
      text-decoration: none;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      transition: border-color .2s ease,background-color .2s ease,color .2s ease;
      &:hover {
        border-color: transparent;
      }
    }
    &:after {
      content: "";
      position: absolute;
      right: -$desktop-margin;
      border-right: 1px solid $white;
      top: 0;
      bottom: 0;
      opacity: 0.8;
    }
  }
  .metadata {
    @include flexBox($display: flex, $flexDirection: column, $justifyContent: center);
    width: 100%;
    .subheader {
      @include font($fontFamily: $font-lato, $size: 16px, $weight: 400, $color: $white, $lh: 24px);
      text-transform: uppercase;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      margin: 0;
    }
    .header {
      @include font($fontFamily: $font-baskerville, $size: 40px, $weight: 400, $style: italic, $color: $white, $lh: 48px);
       display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      word-break: break-word;
      margin: 0;
    }
    .watch-now {
      @include flexBox($display: flex, $flexDirection: row, $alignItems: center);
      @include font($fontFamily: $font-lato, $size: 14px, $weight: 700, $color: $white, $lh: 21px);
      text-transform: uppercase;
      text-decoration: none;
      margin-top: 15px;
      .play-icon {
        width: 15px;
        height: 15px;
        &-wrapper {
          @include flexBox($display: inline-flex, $alignItems: center, $justifyContent: center);
          width: 30px;
          height: 30px;
          border: 2px solid $white;
          border-radius: 100%;
          margin-right: 10px;
        }
      }
      &:hover {
        .play-icon {
          filter: invert(100%);
          &-wrapper {
            background-color: $white;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $tablet) {
  .slide-overlay-info {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    background-color: $black;
    &-wrapper {
      position: relative;
      margin-bottom: 0;
      padding: 0;
      display: block;
      opacity: 1;
      pointer-events: all;
    }
  }
}

@media screen and (max-width: $mobile) {
  .slide-overlay-info {
    flex-direction: column;
    align-items: center;
    .login-ctas {
      width: 100%;
      align-items: center;
      border-top: 1px solid rgba(255, 255, 255, 0.8);
      padding-top: $desktop-margin;
      margin: 0 auto;
      order: 2;
      .btn {
        width: 100%;
      }
      &:after {
        display: none;
      }
    }
    .metadata {
      margin: 0 auto;
      padding-bottom: $desktop-margin;
      align-items: center;
      text-align: center;
      order: 1;
    }
  }
}
