@import "../../styles/base.scss";

.PerformancePage {
  background-color: $black;
  color: $white;
  min-height: 100vh;
  margin: 0 auto;
  padding-bottom: 1.5rem;
  .Grid {
    max-width: $desktop-max-width;
    width: 100%;
    margin: 0 auto;
    padding: 1.875rem;
    .Tile-body {
      &-pre-title, &-header, &-cast, &-performance-date {
        color: $white;
      }
    }
  }
  &.short-form {
    .back-btn {
      display: flex;
      svg {
        position: relative;
        top: .0625rem;
        left: -0.125rem;
        width: 1.25rem;
        height: 0.875rem;
        fill: $white;
      }
      span {
        @include font(
          $fontFamily: $font-lato,
          $size: 0.875rem,
          $weight: 700,
          $color: $white
        );
        text-transform: uppercase;
        margin-left: 0.25rem;
      }
    }
    .Player {
      height: 100%;
    }
    .btn-load-more {
      span {
        color: $white;
      }
      &:hover span {
        color: $thunderbird;
      }
    }
    &.short_form_video > div {
      max-width: 1440px;
      margin: 0 auto;
      .Player {
        &:hover .video-hover,
        &:has(.video-js.bcc-mobile.vjs-user-active) .video-hover {
          opacity: 1;
        }
        .video-hover {
          position: absolute;
          top: 1.5rem;
          left: 50%;
          opacity: 0;
          z-index: 200;
          .back-btn {
            opacity: 0.6;
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .Player-wrapper {
    &.short_form_audio {
      padding: 3rem 0;
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: $tablet-potrait) {
  .PerformancePage {
    .Grid-header h2 {
      font-size: 1.5rem;
    }
    &.short-form {
      .Grid {
        padding: 1.875rem .9375rem !important;
      }
    }
  }
}
@media screen and (max-width: $mobile) {
  .PerformancePage {
    .Player {
      height: 350px;
      .player-overlay-end-screen .video-end-details p {
        margin: 1.625rem 0;
      }
      .video-js.video-ended.logged-out {
        video {
          opacity: 0.35;
        }
      }
      &-wrapper {
        &.short_form_audio {
          padding: 3rem 0 1.5rem;
          .back-btn {
            margin-bottom: 3rem;
          }
        }
      }
    }
    &.short-form {
      .btn-load-more {
        display: flex !important;
        border-color: $white !important;
        span {
          color: $white;
        }
        &:hover {
          background-color: $white !important;
          span {
            color: $black;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $small-mobile) {
  .PerformancePage {
    .Player {
      height: 300px;
      .player-overlay-end-screen .video-end-details p {
        margin: 1.625rem 0;
      }
      .video-js.video-ended.logged-out {
        video {
          opacity: 0.35;
        }
      }
    }
  }
}
