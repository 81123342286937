@import "../../styles/base.scss";

.search {
  &-bar {
    @include flexBox($alignItems: center, $justifyContent: flex-start);
    position: relative;
    border-bottom: .0625rem solid $silver !important;
    &-icon {
      width: 1.25rem;
      height: 1.25rem;
      fill: $pink;
    }
    &-close-icon {
      position: absolute;
      right: 0;
      width: .625rem;
      height: .625rem;
      fill: $black;
      cursor: pointer;
    }
    &-field {
      padding-bottom: 0 !important;
      input {
        @include font($fontFamily: $font-baskerville !important, $size: 1.5rem !important, $lh: 1.5rem !important, $color: $black !important);
        border: 0 !important;
        margin: 0 !important;
        padding: 0 20px 2px 10px !important;
        outline: none;
        &::placeholder {
          color: $pink !important;
        }
      }
    }
  }
  &-text {
    @include font($fontFamily: $font-baskerville, $size: 1rem, $weight: 400, $lh: 1rem);
    margin-top: .31rem;
  }
}
