@import "../../styles/base.scss";

.Grid {
  &-header {
    margin: 1.875rem auto .9375rem;
    h2 {
      @include font($fontFamily: $font-baskerville, $size: 3rem, $weight: 400, $lh: 1.16667);
      margin: 0;
    }
    &-wrapper {
      position: relative;
      pointer-events: none;
    }
    &-accordion {
      position: absolute;
      right: 0;
      span {
        display: none;
        position: relative;
        height: .875rem;
        width: .875rem;
        &:before, &:after {
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          right: 0;
          background-color: $boulder;
          transform: translateY(-50%);
        }
        &:before {
          width: 100%;
          height: .125rem;
        }
        &:after {
          width: .125rem;
          height: 100%;
          right: 50%;
          transform: translateY(-50%) translateX(50%);
          transition: all .2s ease;
        }
      }
    }
    &-media-type-counts {
      @include font($fontFamily: $font-lato, $size: 1rem, $weight: 400, $lh: 1.5, $ls: .03rem);
      text-transform: uppercase;
      margin: 0;
    }
  }
  &-contents {
    @include flexBox($flexWrap: wrap);
    width: 100%;
    & > a {
      width: calc(33.33% - 1.25rem);
      margin: .9375rem;
      color: $black;
      border: .0625rem solid $silver;
      text-decoration: none;
      &:nth-child(3n+1) {
        margin-left: 0;
      }
      &:nth-child(3n+3) {
        margin-right: 0;
      }
    }
  }
  &-items-count {
    display: none;
  }
  &.accordion-desktop {
    &.expanded, &.collapsed {
    .Grid {
      &-header {
        margin: 0;
        &-wrapper {
          @include flexBox($alignItems: center, $justifyContent: space-between);
          width: 100%;
          padding: 1.25rem 1.875rem 1.25rem 0;
          border-top: .0625rem solid $silver;
          cursor: pointer;
          pointer-events: all;
        }
        &-accordion span {
          display: block;
        }
      }
      &-items-count {
        display: none !important;
      }
    }
    &:last-child {
      .Grid-header-wrapper {
        border-bottom: .0625rem solid $silver;
      }
    }
  }
  &.expanded {
    .Grid {
      &-header-accordion span:after {
        opacity: 0;
      }
    }
  }
  &.collapsed {
    .Grid {
      &-contents {
        display: none;
      }
      .btn-load-more {
        display: none !important;
      }
    }
  }
  }
}

@media screen and (max-width: $desktop-small) {
  .Grid {
    &-contents {
      > a {
        width: calc(33.33% - .833rem);
        margin: .625rem;
      }
    }
  }
}

@media screen and (max-width: $tablet) {
  .Grid {
    &-contents {
      > a {
        width: calc(50% - .833rem);
        &:nth-child(3n+1), &:nth-child(3n+3){
          margin: .625rem;
        }
        &:nth-child(2n+1) {
          margin-left: 0;
        }
        &:nth-child(2n+2) {
          margin-right: 0;
        }
      }
    }
  }
}

@media screen and (max-width: $tablet-potrait) {
  .Grid {
    &-header {
      h2 {
        font-size: 2rem;
        line-height: 1.25;
      }
      span {
        margin-left: 0.3125rem;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .Grid {
    &-contents {
      & > a {
        flex: 0 0 100%;
        margin: .9375rem 0;
        &:nth-child(2n+1), &:nth-child(2n+2) {
          margin: .9375rem 0;
        }
      }
    }
  }
  .HomePage .Grid, .PerformancePage:not(.short-form) .Grid {
    &-items-count {
      display: inline-block;
    }
    &-contents {
      display: none;
    }
    &-header {
      margin: 0;
      &-wrapper {
        @include flexBox($alignItems: center, $justifyContent: space-between);
        width: 100%;
        padding: 1.25rem 1.875rem 1.25rem 0;
        border-top: .0625rem solid $silver;
        pointer-events: all;
        cursor: pointer;
      }
      &-accordion span {
        display: block;
        cursor: pointer;
      }
    }
    .btn-load-more {
      display: none !important;
    }
    &.expanded {
      .Grid-header-accordion span:after {
        opacity: 0;
      }
      .Grid-contents {
        display: flex;
      }
      .btn-load-more {
        display: flex !important;
      }
    }
  }

  .PerformancePage .Grid {
    padding: 0 0.9375rem !important;
    &-items-count {
      display: none;
    }
    &-header-wrapper {
      border-bottom: .0625rem solid $silver;
    }
    &.expanded .Grid-contents {
      border-bottom: .0625rem solid $silver;
    }
  }
}
