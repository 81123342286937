@import "../../../styles/base.scss";

.Player-wrapper {
  &.audio, &.audio_stream, &.short_form_audio {
    @include flexBox($flexDirection: column, $alignItems: center);
    max-width: $desktop-max-width;
    margin: 2.5rem auto 7.25rem;
    .performance-info-wrapper {
      @include flexBox($alignItems: flex-start);
      width: 54%;
      margin: 0 auto;
      .performance-image {
        position: relative;
        width: 14.625rem;
        height: 14.625rem;
        &-overlay {
          position: absolute;
          bottom: 1rem;
          left: 0.875rem;
          color: $white;
          p {
            @include font($fontFamily: $font-lato, $size: 0.75rem, $weight: 700, $lh: 1.5, $ls: .03rem);
            text-transform: uppercase;
            margin: 0;
            &:last-child {
              @include font($fontFamily: $font-baskerville, $size: 1.688rem, $weight: 400, $lh: 1.28571, $ls: normal);
              text-transform: none;
            }
          }
        }
        &.audio {
          &-1930s {
            background-color: $nevada;
          }
          &-1940s {
            background-color: $razzmatazz;
          }
          &-1950s {
            background-color: $fern-frond;
          }
          &-1960s {
            background-color: $rose-of-sharon;
          }
          &-1970s {
            background-color: $buttered-rum;
          }
          &-1980s {
            background-color: $blue-lagoon;
          }
          &-1990s {
            background-color: $siren;
          }
          &-2000s {
            background-color: $catalina-blue;
          }
          &-2010s {
            background-color: $killarney;
          }
          &-2020s {
            background-color: $bismark;
          }
        }
      }
      .performance-info {
        padding-left: 2rem;
        .performance-title {
          @include font($fontFamily: $font-baskerville, $size: 2.5rem, $weight: 400, $style: italic, $ls: .07rem, $lh: 1.11111);
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          margin: 0;
          word-break: break-word;
        }
        .performance-composer-date {
          @include font($fontFamily: $font-lato, $size: 0.75rem, $weight: 700, $lh: 0.9rem);
          text-transform: uppercase;
          margin: 0 auto .3125rem auto;
          .separator {
            margin: 0 .5rem
          }
        }
        .performance-active-track {
          &-title {
            @include font($fontFamily: $font-lato, $size: 1.25rem, $weight: 400, $lh: 1.5rem);
            margin-bottom: .3125rem;
          }
          &-cast {
            @include font($fontFamily: $font-lato, $size: 0.75rem, $weight: 400, $lh: 1.5rem);
          }
        }
        .vjs-audio-countdown {
          display: none;
        }
        .subscribe-overlay {
          margin-right: 1rem;
        }
        &.show-login-overlay .vjs-audio-countdown {
          display: none;
        }
      }
    }
    .Player {
      display: none;
      &-controls {
        @include flexBox($alignItems: center);
        margin-top: 1.375rem;
        cursor: pointer;
        img {
          width: 5.43rem;
          height: 5.43rem;
        }
        .play-pause-btn {
          margin-right: 3.75rem;
        }
      }
      .bcc-slider-holder {
        pointer-events: none;
      }
    }
    &.short_form_audio {
      .performance-info-wrapper {
        .performance-image img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .performance-info .performance-title {
          font-style: normal;
        }
      }
    }
  }
  .Player {
    .subscribe-overlay {
      width: 100%;
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      text-align: center;
      z-index: 4;
    }
    .video-js.vjs-login-overlay-showing {
      pointer-events: none;
      z-index: 3;
      &.vjs-live-overlay-countdown .vjs-countdown-modal, #bcc-controls, .vjs-loading-spinner {
        display: none;
      }
    }
  }
  .broadcast-ended-message {
    @include flexBox($alignItems: center, $justifyContent: center, $flexDirection: column);
    @include font($fontFamily: $font-baskerville, $size: 2.5rem, $weight: 400, $style: italic, $ls: .07rem);
    width: 100%;
    height: 15rem;
    margin-bottom: 6rem;
    display: none;
  }
  &.hasEnded {
    .performance-info-wrapper {
      display: none;
    }
  }
}

@media screen and (max-width: $desktop-small) {
  .Player-wrapper {
    &.audio, &.audio_stream, &.short_form_audio {
      padding: 0 0.9375rem;
      margin-bottom: 3rem;
      .performance-info-wrapper {
        width: 90%;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .Player-wrapper {
    &.audio, &.audio_stream, &.short_form_audio {
      margin-top: 1.5rem;
      .performance-info-wrapper {
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .performance-info {
          margin-top: 1.5rem;
          padding-left: 0;
          .performance-title, .performance-composer-date, .performance-active-track {
            text-align: center;
          }
        }
      }
      .Player-controls {
        align-items: center;
        flex-direction: column;

        > div {
          &:not(:last-of-type) {
            margin-right: 0;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $small-mobile) {
  .Player-wrapper {
    &.audio, &.audio_stream, &.short_form_audio {
      .Player-controls {
        flex-direction: column;
        > div {
          margin-right: 0 !important;
          margin-bottom: .625rem !important;
        }
        button {
          min-width: 245px;
        }
      }
    }
  }
}
