@import "../../styles/base.scss";

.MaintenanceOverlay {
  max-width:$desktop-max-width;
  margin: 0 auto $desktop-margin;
  padding: $desktop-margin;
  .header {
    @include font($fontFamily: $font-baskerville, $size: 2.25rem, $weight: 700, $lh: 1.16667);
    margin: 1.875rem auto .9375rem;
    text-transform: uppercase;
  }
  .message {
    @include font($fontFamily: $font-lato, $size: 1.125rem, $weight: 400);
  }
}

@media screen and (max-width: $tablet-potrait) {
  .MaintenanceOverlay {
    .header {
      font-size: 1.75rem;
    }
  }
}
