@import "../../styles/base.scss";
@import "../../../node_modules/slick-carousel/slick/slick.css";
@import "../../../node_modules/slick-carousel/slick/slick-theme.css";

.Hero {
  height: calc(95vh - #{$desktop-margin} * 2);
  position: relative;
  &-header, &-subscriptions-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    width: 100%;
    max-width: $desktop-max-width;
    padding: $desktop-margin;
    margin: 0 auto;
    transition: opacity .4s ease;
    &.active {
      top: calc(#{$desktop-margin} * 2);
      position: fixed;
    }
  }
  &-header {
    .mood-logo {
      width: 300px;
      height: auto;
    }
  }
  &-slider {
    min-height: calc(95vh - #{$desktop-margin} * 2);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    .slick-dots {
      position: relative;
      bottom: 1.875rem;
      padding: 10px 0 $desktop-margin;
      background-color: $black;
      li {
        width: 12px;
        height: 12px;
        border: 1px solid $white;
        margin-left: 15px;
        &:first-child {
          margin-left: 0;
        }
        :before {
          display: none;
        }
        &.slick-active {
          background-color: $white;
        }
      }
    }
  }
  &-navigation-dots {
    position: absolute;
    bottom: 50px;
    left: calc(#{$desktop-max-width} * 0.7 - 41px);
    z-index: 1000;
    transition: opacity .4s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 180px;
    li {
      width: 12px;
      height: 12px;
      border: 1px solid $white;
      z-index: 20;
      cursor: pointer;
      &.active {
        background-color: $white;
      }
      &:hover {
        background-color: $white;
      }
    }
    &.user-logged-in {
      max-height: 120px;
    }
    &.active {
      position: fixed;
      bottom: calc(#{$desktop-margin} * 1.5 + 50px);
    }
  }
  &.scrolled {
    .slide-overlay-info-wrapper {
      position: absolute;
      margin-bottom: 0;
    }
    .Hero-navigation-dots {
      opacity: 0;
    }
  }
  &.fully-scrolled {
    .Hero-header, .Hero-subscriptions-container {
      opacity: 0;
      visibility: hidden;
    }
  }
}

@media screen and (max-width: $desktop-max-width) {
  .Hero {
    &-navigation-dots {
      left: calc(69% - 25px);
    }
  }
}

@media screen and (max-width: $tablet) {
  .Hero {
    position: relative;
    height: 100%;
    &-header {
      display: none;
    }
    &-slider {
      position: relative;
      min-height: 100%;
      overflow: hidden;
      .slick-list {
        background-color: $black;
      }
    }
    &-navigation-dots {
      display: none;
    }
  }
}

@media screen and (max-width: $mobile) {
  .Hero {
    display: flex;
    flex-direction: column;
    &-subscriptions-container {
      order: 2;
      position: relative;
      padding: 0;
    }
    &-slider {
      order: 1;
    }
  }
}
